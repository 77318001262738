import type { RouteQuery } from '#build/graphql-operations'

export default function (query?: Ref<RouteQuery | undefined | null>) {
  if (!query?.value) {
    return
  }

  if (!query.value.route) {
    return
  }

  if (!('schemaOrgMetatags' in query.value.route)) {
    return
  }

  const json = query.value.route.schemaOrgMetatags.json

  if (!json) {
    return
  }

  useHead({
    script: [
      {
        type: 'application/ld+json',
        innerHTML: json,
      },
    ],
  })
}
